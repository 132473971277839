import React, { useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import swal from 'sweetalert';
import { change } from 'redux-form';
import * as Sentry from '@sentry/browser';
import queryString from 'query-string';

import LanguageContext from '../../../../context/language';
import FormTypes from '../../../../libs/constants/form-types';
import { region, brand } from '../../../localizations/current-locale';
import originCheck from '../../../utils/origin-check';
import intlShape from '../../../schemas/intl';
import { sendEvent } from '../../../utils/gtm';
import SubmitBarFigures from './SubmitBarFigures';
import { goToSlide } from '../../../store/actions/api/set-generic-value';
import {
  useSubmitCartAbandonmentMutation,
  useSubmitMortgageCartAbandonmentMutation,
} from '../../../store/reducers/api-call';
import { normalizePhone } from '../../../utils/normalize-phone-number';
import { convertTelToE164 } from '../../../utils/formatters/convert-tel-to-e164';
import hash from '../../../store/actions/api/application/hashFunction';
import style from './style';
import devices from '../../../styles/Devices';

const baseUrl = process.env.REACT_APP_API_ROOT_PAPI || process.env.REACT_APP_API_ROOT;

const TeleButton = styled.button`
  && {
    border: none;
    cursor: pointer;
    line-height: 0;
    text-align: center;
    text-transform: ${style.transform};
    box-shadow: ${style.boxShadow};
    padding: ${(props) => (props.ctaBtnStyle ? style.buttonPadding : 'unset')};
    color: ${(props) => (props.ctaBtnStyle ? style.ctaButtonColor : 'unset')};
    width: ${(props) => (props.width)};
    background: ${(props) => (props.ctaBtnStyle ? style.ctaButtonBackground : 'var(--white)')};
    border-radius: ${(props) => (props.borderRadius)};
    margin-top: 1rem;
    font-family: var(--font-family);
    font-size: var(--text-font-size);

      @media ${devices.downFromTablet} {
        width: 100%;
      }

      &:disabled {
        opacity: 0.6;
        pointer-events: none;
        background: ${style.disabledBg};
        color: ${style.disabledColor};
      }

    ${(props) => props.secondaryButton && css`
      background: var(--white);
      border: ${style.secondaryButtonBorder};
      color: var(--digi-blue-400);
      font-weight: bold;
    `}
  }
`;

const TitleWrapper = styled.div`
  && {
    margin-top: 2rem;
    text-align: ${(props) => (props.centered ? 'center' : '')};
  }
`;

const Title = styled.span`
  && {
    display: inline-block;
    font-weight: bold;
    font-size: 1rem;
  }
`;

const SubTitle = styled.span`
  && {
    display: inline-block;
    font-weight: bold;
    font-size: 13px;
    padding-bottom: 1rem;
  }
`;

const getShouldRenderReturingCustomerSlide = (checkReturningCustomer) => {
  if (!checkReturningCustomer || typeof window === 'undefined') return false;

  const disabledPaths = ['preappsms', 'preappemail'];
  const disabledQueryParams = ['utm_source=sgap', 'utm_source=SGAP'];

  let isDisabledPath = false;

  window.location.href.split('/').forEach((subPaths) => {
    disabledPaths.forEach((disabledPath) => {
      if (subPaths.includes(disabledPath)) {
        isDisabledPath = true;
      }
    });
  });

  if (isDisabledPath) return false;

  // eslint-disable-next-line prefer-destructuring
  const search = window.location.search;
  const queryParams = {
    ...(queryString.parse(search || '')),
  };

  let isDisabledQueryParam = null;
  disabledQueryParams.forEach((parameter) => {
    const parameterValues = parameter.split('=');
    if (queryParams[parameterValues[0]] === parameterValues[1]) {
      isDisabledQueryParam = true;
    }
  });

  if (isDisabledQueryParam) return false;

  return true;
};

const Teleporter = ({
  data,
  passedRef,
  expandedForm,
  disableButton,
  intl: { formatMessage },
  setShowErrors,
  formType,
}) => {
  const dispatch = useDispatch();
  const onGoToSlide = (...args) => dispatch(goToSlide(...args));
  const changeField = (...args) => change('application', ...args);
  const [submitCartabandonment] = useSubmitCartAbandonmentMutation();
  const [submitMortgageCartAbandonment] = useSubmitMortgageCartAbandonmentMutation();
  const formState = useSelector((state) => state.form.application.values);
  const errorsState = useSelector((state) => state.form.application.syncErrors?.applicant);
  const email = useSelector((state) => state?.form?.application?.values?.applicant?.email);
  const phoneNumber = useSelector(
    (state) => state?.form?.application?.values?.applicant?.phoneNumber);
  const shouldCheckForReturningCustomer = getShouldRenderReturingCustomerSlide(
    data?.checkForReturningCustomer,
  );
  const { lang } = useContext(LanguageContext);
  const [language, setLanguage] = useState('sv');

  const digifinansRedirect = () => {
    switch (lang) {
      case 'en':
        return '/en/form-page';
      case 'pl':
        return '/pl/form-page';
      case 'lt':
        return '/lt/form-page';
      default:
        return '/form-page';
    }
  };

  const getQueryParams = () => {
    if (typeof window === 'undefined') return null;

    return window.location.href.split('?').length > 1 ? window.location.href.split('?')[1] : null;
  };

  useEffect(() => {
    if (language !== lang) {
      setLanguage(lang);
    }
  }, [lang]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDestination = (isReturningCustomer) => {
    if (data.destinationIndex >= 0) {
      if (shouldCheckForReturningCustomer && isReturningCustomer) {
        onGoToSlide({ slideIndex: 1 });
      } else {
        onGoToSlide({ slideIndex: data.destinationIndex });
      }
    } else if (data.destinationPath) {
      if (window.location.search !== '') {
        const urlWithQuery = `${data.destinationPath}${window.location.search}`;
        window.location.href = urlWithQuery;
      } else if (lang === 'en') { window.location.href = data.engDestinationPath; } else { window.location.href = data.destinationPath; }
    } else if (data.digiRedirect) {
      const urlWithQuery = `${digifinansRedirect()}${window.location.search}`;
      window.location.href = urlWithQuery;
    }
  };

  const checkReturningCustomer = () => {
    const url = window.location.href;
    const siteDomain = originCheck(url);
    if (typeof phoneNumber !== 'string' || typeof email !== 'string') {
      handleDestination(false);
    }

    let phoneNumberConverted;
    if (phoneNumber.includes('+46')) {
      phoneNumberConverted = phoneNumber.replace('+46', '0');
    } else if (phoneNumber.startsWith('0046')) {
      phoneNumberConverted = phoneNumber.replace('0046', '0');
    } else {
      phoneNumberConverted = phoneNumber;
    }
    fetch(`${baseUrl}/contacts/existing-contact?email=${email}&mobilePhone=${phoneNumberConverted}&siteOrigin=${siteDomain}`)
      .then((res) => res.json())
      .then((res) => {
        const { nationalId } = res.data;
        if (nationalId) {
          /*
            To test locally you might need to change this to your local ssn with the row bellow,
            replace ssnHERE with the one you use locally, since the application might use a
            different one
            dispatch(changeField('applicant', { ...formState.applicant, nationalId: ssnHERE }));
          */
          dispatch(changeField('applicant', { ...formState.applicant, nationalId }));
          handleDestination(true);
          sendEvent({
            event: 'system_reapply_begin',
            content_id: 'reapply',
          });
        } else {
          handleDestination(false);
        }
      })
      .catch((err) => {
        handleDestination(false);
        // eslint-disable-next-line no-console
        console.error('failed to check existing contact', err);
      });
  };

  const handlePostDispatch = () => {
    if (shouldCheckForReturningCustomer) {
      checkReturningCustomer();
    } else {
      handleDestination(false);
    }
  };

  const handleCA = () => {
    const CASubmitValues = {
      ...formState,
      queryParams: getQueryParams(),
    };

    if (data.sendAbandonCartEvent) {
      if (language === 'ar') {
        handlePostDispatch();
        return;
      }
      if (errorsState?.email !== undefined || errorsState?.phoneNumber !== undefined) {
        // TEMP for AB-test "deep-dive" ON-144 - remove sambla when deactivated
        if (brand === 'omalaina' || brand === 'sambla') {
          handlePostDispatch();
        }
        return;
      }
      const submitMethod = formType === FormTypes.MORTGAGE_LOAN
        ? submitMortgageCartAbandonment
        : submitCartabandonment;
      submitMethod(CASubmitValues)
        .unwrap()
        .then(() => {
          const product = formType === FormTypes.MORTGAGE_LOAN ? 'Mortgage' : 'PrivateLoan';
          sendEvent({
            event: 'AddToCart',
            eventCategory: `${region} ${product}`,
            eventAction: `Conversion ${region} ${product}`,
            eventLabel: 'AddToCart',
            formType,
            application: {
              loanTotal: formState.totalAmount,
              loanPurpose: formState.purpose,
              loanType: formState.loanType,
              loanRepaymentTime: formState.repaymentTime,
              loanConsolidationAmount: formState.merge ? formState.consolidationAmount : 0,
              acceptNewsletter: formState.acceptNewsletter,
              autoAccept: formState.autoAccept,
            },
            applicationApplicant: {
              e164phonenumber: hash(convertTelToE164(normalizePhone(formState.applicant.phoneNumber))), //eslint-disable-line
              hashedPhoneNumber: hash(normalizePhone(formState.applicant.phoneNumber)),
              hashedEmail: hash(formState.applicant.email),
            },
          });
          handlePostDispatch();
        })
        .catch(({ errorMessage, response, payload }) => {
          Sentry.setTag('origin', 'cartAbandonment');
          Sentry.captureException(errorMessage, {
            stacktrace: true,
            fingerprint: ['submitCartAbandonment', 'onSubmitCartAbandonment'],
            extra: {
              error: response,
              payload,
            },
          });
          handlePostDispatch();
        });
    } else {
      handlePostDispatch();
    }
  };

  const handleScroll = () => {
    if (passedRef.current !== null) {
      passedRef.current.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  };

  const handleDisabledState = () => (
    disableButton ? setShowErrors(true) : setShowErrors(false)
  );

  const handleClick = () => {
    if (data.closeButton) {
      swal({
        text: data.closeButton.message,
        icon: 'warning',
        buttons: [data.closeButton.yesButton, data.closeButton.noButton],
      })
        .then((abandon) => {
          if (!abandon) {
            handleScroll();
            handleCA();
          }
        });
    } else {
      handleScroll();
      const product = formType === FormTypes.MORTGAGE_LOAN ? 'Mortgage' : 'PrivateLoan';

      let eventLabel = 'click_next';
      let triggerSlide = data.destinationIndex - 1;
      if (data.label === '←') {
        eventLabel = 'click_back';
        triggerSlide = data.destinationIndex + 1;
      }

      if (window.dataLayer) {
        window.dataLayer.push({
          currentSlide: (data.destinationIndex || data.destinationPath) || 0,
        });
      }

      sendEvent({
        event: eventLabel,
        eventCategory: `${region} ${product}`,
        eventAction: eventLabel,
        destinationSlide: (data.destinationIndex || data.destinationPath) || 0,
        triggerSlide,
        formType,
      });

      handleCA();
    }
  };

  let buttonWidth;
  if (data.lead || data.ctaBtnStyle) { buttonWidth = '100%'; } else buttonWidth = 'unset';

  let buttonBorderRadius;
  if (data.lead) { buttonBorderRadius = style.buttonBorderRadiusLead; } else if (data.ctaBtnStyle) { buttonBorderRadius = style.buttonBorderRadius; } else buttonBorderRadius = '100%';

  return (
    <>
      {!expandedForm
        && (
          <div
            className={`${data.ctaBtnStyle ? 'sumbit-bar-anchor' : ''}`}
            onClick={() => data.label.id && handleDisabledState()}
            role="button"
            tabIndex={0}
          >
            {data.title && data.subtitle && (
              <TitleWrapper
                centered={data.centered}
              >
                {data.title
                  && <Title>{formatMessage(data.title)}</Title>}
                {data.subtitle
                  && <SubTitle>{formatMessage(data.subtitle)}</SubTitle>}
              </TitleWrapper>
            )}

            {data.ctaBtnStyle
              && <SubmitBarFigures />}

            <TeleButton
              secondaryButton={data.secondaryButton}
              borderRadius={buttonBorderRadius}
              width={buttonWidth}
              ctaBtnStyle={data.ctaBtnStyle}
              lead={data.lead}
              onClick={() => {
                handleClick();
              }}
              type="button"
              tabIndex={0}
              disabled={data?.disableButton === false
                ? data.disableButton : disableButton}
            >
              <span>
                {data.label?.id ? (
                  <>{formatMessage(data.label)}</>
                ) : (
                  <>{data.label}</>
                )}
              </span>
            </TeleButton>
          </div>
        )}
    </>

  );
};

Teleporter.propTypes = {
  intl: intlShape.isRequired,
  data: PropTypes.shape().isRequired,
  expandedForm: PropTypes.bool,
  disableButton: PropTypes.bool,
  passedRef: PropTypes.shape(),
  setShowErrors: PropTypes.func,
  formType: PropTypes.oneOf(Object.values(FormTypes)),
};

Teleporter.defaultProps = {
  disableButton: false,
  expandedForm: false,
  passedRef: {},
  setShowErrors: () => { },
  formType: FormTypes.MERGE_LOAN,
};

const TeleporterWithIntl = injectIntl(Teleporter);

export { TeleporterWithIntl as Teleporter };

export default TeleporterWithIntl;
